:root {
  --main: #a92f22;
  --main-1: #a92f22;
  --main-2: #a92f22;

  --text: #28314d;

  --background: #fbf8f1;

  --dark-1: hsl(0, 0%, 23%);

  --quicket: #5eb700;
  --quicket-2: hsl(89, 29%, 66%);

  /*JEEVES*/
  --cornsilk: hsla(50, 100%, 94%, 1);
  --wenge: hsla(332, 6%, 39%, 1);
  --apricot: hsla(26, 95%, 85%, 1);
  --thistle: hsla(269, 81%, 90%, 1);
  --wenge-2: hsla(335, 6%, 41%, 1);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--background);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  display: block;
  font-family: "Lato", sans-serif;
  color: var(--main-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  max-width: 350px;
  width: 70%;
  margin: 50px auto;
  border: none;
  height: 2px;
  background: var(--main-2);
}

/*
font-family: 'Kiwi Maru', serif;
font-family: 'Lato', sans-serif;
font-family: 'New Tegomin', serif;
*/

h1,
h2,
h3,
h4,
h5,
h6,
p {
  a {
    color: var(--text);
    font-size: inherit;
    margin: 0;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

.modal-button {
  background-color: var(--text);
  display: block;
  text-align: center;
  color: var(--main);
  line-height: 45px;
  max-width: 250px;
  margin: auto;
  margin-top: -20px;
  margin-bottom: 10px;
  text-decoration: none;
}

blockquote {
  display: block;
  padding: 40px 25px;
  margin: 0;
  text-align: center;
  font-size: 80%;
  line-height: 1.6;
  font-style: italic;
  border-top: solid 1px var(--main);
  border-bottom: solid 1px var(--main);
}
